%display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
}
@mixin display-flex() {
  @extend %display-flex;
}

%flex-direction-row {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
%flex-direction-row-reverse {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
%flex-direction-column {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
%flex-direction-column-reverse {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
%flex-direction-inherit {
  -webkit-box-orient: inherit;
  -moz-box-orient: inherit;
  -webkit-flex-direction: inherit;
  -moz-flex-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
}

%fx-fill {
  margin: 0px;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}
@mixin flex-direction($direction: row) {
  @if not index((row, row-reverse, column, column-reverse, inherit), $direction)
  {
    $direction: row;
  }
  @extend %flex-direction-#{$direction};
  @if index((row, row-reverse), $direction) {
    @include flex-gaps(right);
  } @else if index((column, column-reverse), $direction) {
    @include flex-gaps(bottom);
  }
}

%flex-wrap-nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
%flex-wrap-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
%flex-wrap-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  -moz-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
%flex-wrap-inherit {
  -webkit-flex-wrap: inherit;
  -moz-flex-wrap: inherit;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
}
@mixin flex-wrap($wrap: nowrap) {
  @if not index((nowrap, wrap, wrap-reverse, inherit), $wrap) {
    $wrap: nowrap;
  }
  @extend %flex-wrap-#{$wrap};
}

@mixin flex-flow($direction, $wrap) {
  @include flex-direction($direction);
  @include flex-wrap($wrap);
}

@mixin order($order: 0) {
  -webkit-box-ordinal-group: ($order + 1);
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  -webkit-order: $order;
  -moz-order: $order;
  order: $order;
}

@mixin flex-grow($grow: 0) {
  -webkit-flex-grow: $grow;
  -webkit-box-flex: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-positive: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink: 1) {
  -webkit-flex-shrink: $shrink;
  -webkit-box-flex: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-negative: $shrink;
  flex-shrink: $shrink;
}

@mixin flex-basis($basis: auto) {
  -webkit-flex-basis: $basis;
  -moz-flex-basis: $basis;
  -ms-flex-preferred-size: $basis;
  flex-basis: $basis;
}

@mixin flex($grow: 0, $shrink: 1, $basis: auto) {
  @include flex-grow($grow);
  @include flex-shrink($shrink);
  @include flex-basis($basis);
}

@mixin flex-position($align: flex-start, $justify: flex-start) {
  place-content: $align $justify;
  align-items: $align;
}

@mixin flex-gaps($gap: bottom) {
  $position: (5, 10, 15, 20, 25);
  @each $var in $position {
    &.fx-#{$var} {
      > :not(:last-child) {
        margin-#{$gap}: #{$var + "px"};
      }
    }
  }
}
